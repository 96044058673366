import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';

import '../css/pages/_about-page.scss';

export default function about({ data }) {
  const aboutData = data.sanityAboutpage;

  const serializers = {
    marks: {
      /* eslint-disable */
      link: ({ children, mark }) => (
        <a href={mark.href} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      /* eslint-enable */
    },
  };

  return (
    <Layout>
      <SEO title="About us" />
      <div className="about-page">
        {/* Hero section */}
        <Hero h1={aboutData.title} />

        <section className="about-content">
          <div className="wrapper">
            <div className="about-img">
              <GatsbyImage
                image={aboutData.image.asset.gatsbyImageData}
                alt={aboutData.image.alt}
                className="img"
              />
            </div>

            <div className="content">
              <PortableText
                blocks={aboutData._rawDesc}
                serializers={serializers}
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    sanityAboutpage {
      title
      _rawDesc
      image {
        alt
        asset {
          gatsbyImageData
        }
      }
    }
  }
`;
